const types = {
  403: {
    img: require('@/assets/403.svg'),
    title: '403',
    desc: '抱歉，你无权访问该页面'
  },
  404: {
    img: require('@/assets/404.svg'),
    title: '404',
    desc: `对不起，您访问的页面找不到啦！<br>` +
      `<div style="font-size: 16px"><span>·页面不存在或者已失效</span><br><span>·输入的地址不正确</span></div>`
  },
  500: {
    img: require('@/assets/500.svg'),
    title: '500',
    desc: '抱歉，服务器出错了'
  },
  undefined: {
    img: require('@/assets/none.svg'),
    title: '内容不见了',
    desc: '啊叻？内容不见了？'
  }
}


export default types
