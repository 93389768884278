<template>
  <div class="exception">
    <div class="img">
      <img :src="config[type].img"/>
    </div>
    <div class="content">
      <!--      <h1>{{ config[type].title }}</h1>-->
      <div class="desc" v-html="config[type].desc"></div>
      <div class="action">
        <el-button type="primary" @click="handleToHome">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import types from './type'

  export default {
    name: 'Exception',
    props: {
      type: {
        type: String,
        default: '404'
      }
    },
    data() {
      return {
        config: types
      }
    },
    methods: {
      handleToHome() {
        this.$router.push({ path: '/bayareacertification/index' })
      }
    }
  }
</script>

<style lang="less" scoped>
/deep/ .el-button {
  border: none;
  background-color: @primary-color;
}

/deep/ .el-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.exception {
  min-height: 500px;
  height: 80%;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 50px;

  .img {
    padding-right: 52px;
    zoom: 1;

    img {
      height: 280px;
      max-width: 350px;
    }
  }

  .content {
    flex: auto;

    h1 {
      color: #434e59;
      font-size: 72px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 24px;
    }

    .desc {
      color: rgba(0, 0, 0, .45);
      font-size: 20px;
      line-height: 28px;
      margin-top: 26px;
      margin-bottom: 40px;
    }
  }
}

.mobile {
  .exception {
    margin-top: 30px;

    .img {
      padding-right: unset;

      img {
        height: 40%;
        max-width: 80%;
      }
    }
  }
}
</style>
